.msgs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 85%;
    width: 100%;
    overflow-y: auto;
    padding: 4px 10px;
}

.msg-box {
    width: 90%;
    height: 90%;
    border-radius: 15px;
    border: 2px solid #e2e8f0;
    padding: 10px 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.msg-input {
    height: 100%;
    border: none;
    font-size: 20px;
    padding-left: 10px;
    outline: none;
    flex-grow: 1;
}

.msg-form {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    width: 100%;
    align-items: center;
}

.msg-send-btn {
    color: #000080;
    background-color: transparent;
    height: 95%;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    padding: 0;
    width: fit-content;
}

.chat-box {
    display: flex;
    height: 100%;
    align-items: center;
    width: 75vw;
}

.attach-btn {
    all: unset;
    cursor: pointer;
}

.chat-area {
    display: flex;
    width: 55vw;
    flex-direction: column;
    height: 100%;
}

.chat-directory {
    height: 100%;
    width: 20vw;
    border-left: 0.5px solid rgba(198, 198, 198, 0.726);
}

.members-dir,
.files-dir {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    overflow-y: auto;
    padding: 8px;
    box-sizing: border-box;
}

.members-dir {
    height: 60%;
    border-bottom: 0.5px solid rgba(198, 198, 198, 0.726);
}

@media (max-width: 850px) {
    .chat-box {
        display: none;
        width: 100%;
    }
    .chat-directory {
        display: none;
        width: 100%;
    }
    .chat-area {
        width: 100%;
    }
    .msgs {
        height: 90%;
    }
}
