.searchbar {
    height: 45px;
    border-radius: 10px;
    background-color: #f1f1f1;
    border: none;
    outline: none;
    padding-left: 25px;
    order: -1;
}

.user-search {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
}
