.chat-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    border-right: 0.5px solid rgba(198, 198, 198, 0.726);
    row-gap: 20px;
}

.chat-item {
    height: 15%;
    width: 95%;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    border-radius: 15px;
    padding-left: 5px;
}

.profile-pic {
    height: 50px;
    width: 50px;
    border-radius: 6px;
    margin-right: 10px;
    border-radius: 10px;
    object-fit: cover;
}

.user-chat {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
}

.unseen-msgs {
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    font-size: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.chat-notifiers {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.latest-msg,
.user-name {
    color: grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-info {
    display: flex;
}
.custom-checkbox {
    border: 1.5px solid #2563eb;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
}

.custom-checkbox-clicked {
    background-color: #2563eb;
}

.custom-checkbox-clicked:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7.5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: block;
}

.notfication-profile-pic {
    height: 30px;
    width: 30px;
}

.notification-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 5px;
    width: 70%;
}

.notification-text > h4 {
    margin: 0;
}

.notification-msg {
    width: 100%;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-basic-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 70%;
}

.MuiCircularProgress-circle {
    margin: auto;
}

@media (max-width: 850px) {
    .chat-list {
        width: 100% !important;
    }
}
