.msg {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: fit-content;
    column-gap: 15px;
}

.user-profile-img {
    height: 50px;
    width: 50px;
    border-radius: 6px;
    align-self: flex-start;
}

.msg-content {
    background-color: #f1f1f1;
    max-width: 450px;
    border-radius: 15px;
    padding: 10px 20px;
    margin: 0;
}

.sent {
    align-self: flex-end;
}

.sent .msg-content {
    background-color: #000080;
    color: white;
}

.file-msg {
    background-color: white;
    border: 2px solid #f1f1f1;
    border-radius: 13px;
    width: fit-content;
    padding: 20px 25px;
    display: flex;
    column-gap: 12px;
    align-items: center;
    height: 100%;
}

.file-info {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    color: black;
}

.file-name {
    font-weight: bold;
}

.file-size {
    color: #7d7d7d;
    font-size: small;
}

.file-icon {
    height: 35px;
    width: 35px;
    border: 2px solid #f1f1f1;
    border-radius: 5px;
    padding: 5px;
}

.file-link {
    background-color: transparent !important;
    padding: 0 !important;
}

.msg-visual {
    height: 200px;
    width: 350px;
    object-fit: contain;
    border-radius: 10px;
    background-color: #000080;
}

.img-loader {
    color: white;
}

@media (max-width: 850px) {
    .msg-visual {
        height: 137px;
        width: 275px;
    }
    .sent .msg-content,
    .sent .msg-visual {
        border-top-right-radius: 0;
    }
    .received .msg-content,
    .received .msg-visual {
        border-top-left-radius: 0;
    }
    .user-profile-img {
        border-radius: 50%;
    }
}
