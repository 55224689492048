.file-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    row-gap: 20px;
}

.file-data {
    display: flex;
    align-items: center;
    column-gap: 12px;
    width: 60%;
}

.download-file-btn {
    background-color: white;
    border: 2px solid rgb(0, 0, 128);
    border-radius: 10px;
    color: rgb(0, 0, 128);
    cursor: pointer;
}

.file-chip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}
.file-icon-wrapper{
    height: fit-content;
    width: fit-content;
    padding: 6px;
    color: rgb(66, 153, 225);
    background-color: rgb(235, 248, 255);
    display: flex;
    align-items: center;
    border-radius: 10px;
}
