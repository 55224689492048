


@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@600&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.textInfo{
    font-family: 'Caveat', cursive !important;
font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif;

}