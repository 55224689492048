.App {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sign-in,
.sign-out {
    height: 10vh;
    width: 15vw;
    border-radius: 7px;
    border: none;
    background-color: #000080;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin: auto;
}

.login-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
}

.chats {
    display: flex;
    height: 90vh;
}

@media (max-width: 850px) {
    .chats {
        height: 95vh;
    }
}
